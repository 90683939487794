<template>
    <div class="d-flex align-center nomination-chip" v-if="pNomination.nominationApprovalState">
      <v-chip
        class="nomination-state"
        :class="[chipColor, {'accepted-chip': visibleAvatar}]"
      >
        {{ pNomination.getDisplayApprovalState() }}
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-if="visibleAvatar"
              class="viewer-info-circle ml-1 mr-0"
              v-bind="attrs"
              v-on="on"
              color="darken-3"
              :class="chipColor"
            >
              {{ pNomination.viewerUsers.length }}
            </v-avatar>
          </template>
          <span>{{ pNomination.viewerUsers.length }} Viewers accepted</span>
        </v-tooltip>
      </v-chip>
    </div>
</template>

<script>
import {
  NOMINATION_STATE_ACCEPTED,
  NOMINATION_STATE_RESIGNED,
  NOMINATION_STATE_APPROVED,
  NOMINATION_STATE_PROMOTED,
  NOMINATION_STATE_CANCELED,
  NOMINATION_STATE_DECLINED,
  NOMINATION_STATE_DENIED,
  NOMINATION_STATE_DRAFT,
  NOMINATION_STATE_EXPIRED,
  NOMINATION_STATE_FINAL_INVITE,
  NOMINATION_STATE_INITIAL_INVITE,
  NOMINATION_STATE_NOMINATED,
  NOMINATION_STATE_REMOVED,
} from "../../utils/utils";

export default {
  name: "NominationStateComponent",

  props: {
    pNomination: Object,
  },

  data: () => ({
    NOMINATION_STATE_ACCEPTED,
  }),

  computed: {
    visibleAvatar() {
      return this.pNomination.nominationApprovalState === NOMINATION_STATE_ACCEPTED.value
        && this.pNomination.viewerUsers.length;
    },

    chipColor() {
      const state = this.pNomination.nominationApprovalState;

      if (state === NOMINATION_STATE_ACCEPTED.value) return ["green", "white--text"];

      if (state === NOMINATION_STATE_RESIGNED.value) return ["grey", "white--text"];

      if (state === NOMINATION_STATE_APPROVED.value) return ["green", "white--text"];

      if (state === NOMINATION_STATE_PROMOTED.value) return ["purple", "white--text"];

      if (state === NOMINATION_STATE_DENIED.value) return ["red", "white--text"];

      if (state === NOMINATION_STATE_CANCELED.value) return ["red", "white--text"];

      if (state === NOMINATION_STATE_DECLINED.value) return ["red", "white--text"];

      if (state === NOMINATION_STATE_EXPIRED.value) return ["red", "white--text"];

      if (state === NOMINATION_STATE_DRAFT.value) return ["grey", "white--text"];

      if (state === NOMINATION_STATE_FINAL_INVITE.value) return "yellow";

      if (state === NOMINATION_STATE_INITIAL_INVITE.value) return "yellow";

      if (state === NOMINATION_STATE_NOMINATED.value) return ["blue", "white--text"];

      if (state === NOMINATION_STATE_REMOVED.value) return ["red", "white--text"];

      return "";
    },
  },
};
</script>

<style lang="scss">
.nomination-chip {
  .v-chip.accepted-chip {
    padding: 0 6px 0 10px;
  }
}
</style>
